<template>
  <transition-group name="list" tag="div">
    <drag
      v-for="item in $store.state.playlist.itemsAds"
      :data="item"
      class="item"
      :key="item._id"
    >
      <v-list-item @click="preview(item)">
        <v-list-item-content class="text-truncate text-nowrap">
          <v-card>
            <v-card-text>{{ item.name }}</v-card-text>
          </v-card>
        </v-list-item-content>
      </v-list-item>
    </drag>
  </transition-group>
</template>

<script>
import { Drag } from "vue-easy-dnd";
export default {
    components:{Drag},
    methods:{
        preview(item){
            this.$store.state.playlist.previewAds = item
        }
    }
    };
</script>

<style lang="scss" scoped>
</style>