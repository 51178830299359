<template>
  <v-card class="mx-auto" height="850" ref="preview">
    <v-card-title>
      Pré visualização
      <v-spacer></v-spacer>
      <!-- <v-btn small color="primary">Templates</v-btn> -->
    </v-card-title>

    <v-card tile class="d-flex justify-center mb-6">
      <div class="preview" :style="{ zoom: previewZoom, overflow: 'hidden' }">
        <Template1
          v-if="currentTemplate.name === 'Template 1' && !['Vídeo', 'Imagem'].includes($store.state.news.mediaType)"
          :template="currentTemplate"
          @end="nextTemplate"
        />
        <Template2
          v-if="currentTemplate.name === 'Template 2' && !['Vídeo', 'Imagem'].includes($store.state.news.mediaType)"
          :template="currentTemplate"
          @end="nextTemplate"
        />
        <Template3
          v-if="currentTemplate.name === 'Template 3' && !['Vídeo', 'Imagem'].includes($store.state.news.mediaType)"
          :template="currentTemplate"
          @end="nextTemplate"
        />
        <Template4
          v-if="currentTemplate.name === 'Template 4' && !['Vídeo', 'Imagem'].includes($store.state.news.mediaType)"
          :template="currentTemplate"
          @end="nextTemplate"
        />
        <Template5
          v-if="currentTemplate.name === 'Template 5' && !['Vídeo', 'Imagem'].includes($store.state.news.mediaType)"
          :template="currentTemplate"
          @end="nextTemplate"
        />

        <PreviewMedia v-if="['Vídeo', 'Imagem'].includes($store.state.news.mediaType)" />

        <div class="content" v-if="showPreviewDefault && !['Vídeo', 'Imagem'].includes($store.state.news.mediaType)" style="background: #fff">
          <img
            src="/img/territorio.png"
            style="width: 300px"
            class="logo-top"
            alt=""
          />
          <div class="dec" :style="{ 'border-color': $store.state.news.color }">
            <img
              :src="$store.state.news.img"
              alt=""
              width="1080"
              height="1000"
            />
            <div
              class="copyright-image"
              style="font-size: 25pt"
              v-if="$store.state.news.copyright"
            >
              <b>Foto:</b> {{ $store.state.news.copyright }}
            </div>
          </div>

          <div
            class="dot"
            :style="{ 'background-color': $store.state.news.color }"
          ></div>
          <div class="text" v-html="$store.state.news.text.toUpperCase()"></div>
          <div class="logo">
            <img :src="$store.state.news.logo" width="200" alt="" />
          </div>
          <Qr :color="$store.state.news.color" :code="img" />
          <div class="foot" :style="{ background: $store.state.news.color }">
            <div class="textfoot"><b>FONTE</b> {{ link }}</div>
          </div>
        </div>
      </div>
    </v-card>
  </v-card>
</template>

<script>
import Template1 from "../../../components/Templates/template1/index.vue";
import Template2 from "../../../components/Templates/template2/index.vue";
import Template3 from "../../../components/Templates/template3/index.vue";
import Template4 from "../../../components/Templates/template4/index.vue";
import Template5 from "../../../components/Templates/template5/index.vue";
import PreviewMedia from '@/views/News/previewMedia.vue'
import Qr from "./Qr.vue";
export default {
  components: {
    Qr,
    Template1,
    Template2,
    Template3,
    Template4,
    Template5,
    PreviewMedia
  },
  props: ["img", "title"],
  data() {
    return {
      logo: "",
      previewZoom: 0.3,
      currentTemplate: {},
    };
  },

  beforeDestroy() {
    this.$store.state.templates.items = [];
    this.$store.state.news = this.$store.state.originalNews;
  },

  computed: {
    showPreviewDefault() {
      return !this.$store.state.news.templates.length;
    },
    link() {
      try {
        const url = new URL(this.$store.state.news.link);
        return url.origin;
      } catch (e) {
        return this.$store.state.news.link;
      }
    },
  },
  methods: {
    play() {
      this.currentTemplate = {};
      this.$nextTick(
        () =>
          (this.currentTemplate = {
            ...(this.$store.state.templates.items[0] || {}),
            index: 0,
          })
      );
    },
    nextTemplate(index) {
      if (this.$store.state.templates.items[index + 1])
        return (this.currentTemplate = {
          ...this.$store.state.templates.items[index + 1],
          index: index + 1,
        });
      this.play();
    },
  },
  mounted() {
    this.$nextTick(() => {
      const w = Number(
        ((this.$refs.preview.$el.offsetWidth - 30) / 1080).toFixed(2)
      );
      const h = Number(
        ((this.$refs.preview.$el.offsetHeight - 80) / 1920).toFixed(2)
      );
      this.previewZoom = w < h ? w : h;
      this.play();
    });
  },
  watch: {
    "$store.state.templates.items": {
      deep: true,
      handler(v) {
        if (v.length) {
          this.play();
        }
      },
    },
  },
};
</script>
<style scoped>
.preview {
  position: absolute;
  width: 1080px;
  height: 1920px;
  overflow: hidden;
}
.copyright-image {
  position: absolute;
  text-shadow: 2px 2px #000;
  height: 50px;
  bottom: 70px;
  left: 50px;
  color: white;
}
.logo-top {
  position: absolute;
  width: 150px;
  left: 5px;
}
.logo {
  position: absolute;
  bottom: 180px;
  left: 20px;
  background: white;
}
.textfoot {
  color: white;
  position: absolute;
  top: 50px;
  left: 20px;
  font-family: arial;
  font-size: 20pt;
}

.foot {
  position: absolute;
  bottom: -80px;
  width: 1100px;
  height: 250px;
  left: -5px;
  transform: rotate(-5deg);
}
.foot >>> * {
  transform: rotate(5deg);
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: arial;
}
.dec {
  position: relative;
  overflow: hidden;
  text-align: center;
  margin-top: 135px;
  left: 0;
  width: 1188px;
  height: 768px;
  /* background-color: rgb(228, 128, 128); */
  margin-left: -54px;
  border-bottom-left-radius: 0;
  border-top: solid 20px;
  border-bottom: solid 20px;
  border-top-left-radius: 324px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 324px;
  transform: rotate(-6deg);
}
.dec > * {
  transform: rotate(6deg);
  margin-top: -100px;
}

.dot {
  position: absolute;
  height: 50px;
  width: 50px;
  left: 850px;
  top: 834px;
  border-radius: 50px;
}
.text {
  margin-top: 100px;
  padding: 30px;
  font-weight: bold;
  font-size: 40pt;
  font-family: arial;
}
</style>
