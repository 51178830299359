<template>
  <v-card class="overflow-y-auto" height="670">
    <v-card-title>
      <v-select
        label="Notícias ou Anúncios"
        :items="['Notícias', 'Anúncios']"
        v-model="$store.state.playlist.type"
        hide-details
        @change="change"
      ></v-select>
    </v-card-title>

    <v-text-field class="pl-3 pr-3" placeholder="Buscar" @keyup="$store.commit('playlist/filter', $event)"/>

    <v-list-item-group>
      <v-list>
        <list-ads  v-if="$store.state.playlist.type == 'Anúncios'"></list-ads>
        <list-news v-if="$store.state.playlist.type == 'Notícias'"></list-news>
      </v-list>
    </v-list-item-group>

    <slot name="card-footer"></slot>
  </v-card>
</template>


<script>
import ListAds from "./ListAds";
import ListNews from "./ListNews";

import { getAdsActives, getNewsActives } from "../../../services/playlists";
export default {
  components: {
    ListAds,
    ListNews,
  },
  methods: {
    async change(value) {
      this.$store.state.loading = true;
      const changeData = { Anúncios: getAdsActives, Notícias: getNewsActives };
      changeData[value]().catch((err) => this.$store.commit("failed", err));
      this.$store.state.loading = false;
    }
  },
};
</script>

