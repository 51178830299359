<template>
  <div>
    <v-row class="pl-2 pr-2">
      <v-col md="4"><list-card /></v-col>
      <v-col md="4"><time-line-with-icon /></v-col>
      <v-col md="4">
        <PreviewNews v-if="$store.state.playlist.type == 'Notícias'" />
        <PreviewAds v-if="$store.state.playlist.type == 'Anúncios'" />
      </v-col>
      <!-- <v-col md="4" v-if="false"><time-line-with-icon  class="elevation-5"/></v-col> -->
    </v-row>
  </div>
</template>

<script>
import {getNewsActives} from '../../../services/playlists'
import TimeLineWithIcon from './TimelineNews.vue';
import ListCard from './ListCard.vue';
import PreviewNews from './previewNews'
import PreviewAds from './previewAds'
// @ is an alias to /src
export default {
  name: "Schedules",
  components: { TimeLineWithIcon, ListCard, PreviewNews, PreviewAds },
  data(){
    return {
      desserts: []
    }
  },
  beforeDestroy() {
  },
  mounted(){
    getNewsActives()
  }
};
</script>

<style scoped>

</style>