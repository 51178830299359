<template>
  <transition-group name="list" tag="div">
    <drag
      v-for="item in $store.state.playlist.itemsNews"
      :data="item"
      class="item"
      :key="item._id"
    >
      <v-list-item @click="preview(item)">
        <v-list-item-content class="text-truncate text-nowrap">
          <v-card class="card">
            <div class="faixa-lateral" :style="{backgroundColor: item.user.color}"></div>
            <v-card-text>
              <p class="text-h6 ">
                {{item.user.name}}
              </p>
              <b>Última playlist: </b><span style="color: blue">{{getPlaylists(item)}}</span>
              <div>{{ item.text }}</div>
            </v-card-text>
          </v-card>
        </v-list-item-content>
      </v-list-item>
    </drag>
  </transition-group>
</template>

<script>
import { Drag } from "vue-easy-dnd";
export default { 
    components: { Drag },
    methods: {
        preview(item){
            this.$store.state.playlist.preview = item;
            this.$store.state.news = item
            this.$store.state.templates.items = item.templates
        },
        getPlaylists(item){
          const playlist = item.playlists[item.playlists.length - 1]
          return playlist ? playlist.name + ' - ' + item.total : 'Nunca foi utilizado'
        }
    }
    };
</script>

<style lang="scss" scoped>
.faixa-lateral{
  position:absolute;
  width:10px;
  top: 0;
  height: 100%;
  border-radius: 0px !important;
}
.card{
  overflow: hidden;
}
</style>